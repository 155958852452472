@import '../../../../scss/theme-bootstrap';

.site-email-signup {
  margin: auto;
  &__title {
    text-align: center;
  }
  &__subtitle {
    text-align: center;
    font-style: italic;
  }
  &__copy {
    text-align: center;
  }
  &__messages {
    text-align: $ldirection;
  }
  &__message {
    &--error {
      color: red;
    }
    &--success {
      color: green;
    }
  }
  &__fields {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto;
  }
  &__field {
    display: block;
    margin: auto;
    margin-bottom: 10px;
    padding-bottom: 0;
  }
  input[type='email'].site-email-signup__field,
  input[type='tel'].site-email-signup__field,
  input[type='text'].site-email-signup__field {
    display: block;
    width: 100%;
    @include input-placeholder {
      color: $color-light-gray;
    }
  }
  &__terms {
    display: none;
  }
  &__terms-conditions {
    display: none;
    position: relative;
    &__checkbox,
    &__more {
      margin-bottom: 20px;
      font-size: 13px;
    }
    &__checkbox {
      .label p {
        display: inline;
      }
    }
    &__more label {
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
    }
    &.error {
      color: $color-red;
    }
  }
  input[type='submit'].site-email-signup__submit {
    display: block;
    margin: auto;
    width: 100%;
    &:hover {
      border-color: $color-off-black;
    }
  }
  &__success {
    position: relative;
    text-align: center;
    padding: 2em 0;
    background-color: $color-light-linen;
    &-header {
      text-transform: uppercase;
    }
    &-offer {
      line-height: 1.5em;
      &__code {
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    &-cta {
      margin-bottom: 2em;
    }
    &-reminder {
    }
  }
  &__terms-checkbox {
    font-size: 13px;
    margin-bottom: 20px;
    &--content {
      display: inline;
    }
  }
  &__privacy {
    padding-bottom: 10px;
    font-size: 13px;
    line-height: 1.4;
  }
}
